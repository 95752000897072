

import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';




import React from "react"
import i18next from '../i18n/config';
import ImgPackagingInstant from "../img/F_opakowania_2/opakowania_inst_iko.png";
import ImgPackagingWhole from "../img/F_opakowania_2/opakowania_ziar_iko.png";
import ImgPackagingGround from "../img/F_opakowania_2/opakowania_mielona_iko.png";
import ImgPackagingCapsules from "../img/F_opakowania_2/opakowania_kapsulka_iko.png";


import Imgiko_design from "../img/icon/iko_design.png";
import Imgiko_opak from "../img/icon/iko_opak.png";



export default function Header(props) {



    return (
            <React.Fragment>

                <section id="ourpackaging" class="ourcoffees firstSection">
                    <h1>{i18next.t('OurPackagingMainH1')}</h1>
                    <p>{i18next.t('OurPackagingMainP')}</p>

                    <div class="icons">
                        <Link to={'/OurPackaging/Packaging/InstantPackaging'}>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgPackagingInstant} alt="Instanta Our Coffee" /></div>
                            <p>{i18next.t('OurPackagingMainLink1')}</p>

                        </div>
                        </Link>

                        <Link to={'/OurPackaging/Packaging/WholeBeanPackaging'}>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgPackagingWhole} alt="Instanta Our Coffee" /></div>
                            <p>{i18next.t('OurPackagingMainLink2')}</p>
                        </div>
                        </Link>
                        <Link to={'/OurPackaging/Packaging/GroundPackaging'}>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgPackagingGround} alt="Instanta Our Coffee" /></div>
                            <p>{i18next.t('OurPackagingMainLink3')}</p>
                        </div>
                        </Link>
                        <Link to={'/OurPackaging/Packaging/Capsules'}>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgPackagingCapsules} alt="Instanta Our Coffee" /></div>
                            <p>{i18next.t('OurPackagingMainLink4')}</p>
                        </div>
                        </Link>
                    </div>


                    <aside class="mininavi">
                        <div>
                            <Link to=
                                  {'/OurPackaging/Design/'}
                                  > <img loading="lazy" src={Imgiko_design}
                                  alt="Instanta Design" />
                            <p>
                                {i18next.t('Design')}
                            </p>
                            </Link>
                        </div>

                    </aside>


                </section>



            </React.Fragment>

            )


}
